@import "./_variables";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,600&display=swap");

body {
  margin: 0;
  color: $text-color;
  background-color: $backgroundcolor-main;
  font-family: "Poppins", sans-serif;
}

.custom-label {
  color: $grey-light;
  font-size: 13px;
}

.custom-form-group {
  margin-bottom: 10px;
}

.error-message {
  color: red;
  font-size: 12px;
}

.btn-block {
  background-color: $button-color-primary !important;
  margin-right: 10px !important;
  color: white !important;
}

.btn-light {
  border-color: $primary-100 !important;
  color: $primary-100 !important;
}

.custom__rich-editor.disabled-editor {
  caret-color: transparent;
}

.MuiOutlinedInput-root {
  input {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
  }
  fieldset {
    border: none;
    legend {
      display: none;
    }
  }
}

.MuiOutlinedInput-root:hover {
  input {
    border-color: rgba(0, 0, 0, 0.23) !important;
  }
}

.Mui-focused {
  input {
    border-color: rgba(0, 0, 0, 0.23) !important;
    border-width: 1px !important;
  }
}

.custom-select-field {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  height: 42px;
  padding: 18.5px 14px;
  .MuiSelect-select:focus {
    background-color: unset;
  }

  &::before,
  &::after {
    border-bottom: unset !important;
  }
}

.custom-select-field.Mui-focused {
  .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
    border-width: 1px;
  }
}

.custom-select-field:hover {
  .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
    border-width: 1px;
  }
}

.custom-card {
  box-shadow: none !important;
  overflow: unset !important;
}

.custom-text-area {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;
}
.custom-text-area.disabled {
  color: rgba(0, 0, 0, 0.38);
  background-color: white;
}

.custom-text-area:focus {
  outline: none;
}

.custom-dropzone.main-uploader {
  .custom-image-uploader {
    left: 0;
    right: 0;
  }
}
.custom-dropzone {
  height: 100px;
  width: 275px;
  border: 1px dashed $grey-light;
  border-radius: 3px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $grey-light-100;

  .add-icon {
    display: none;
  }

  svg {
    font-size: 60px;
  }
  .custom-image-uploader {
    position: absolute;
    top: 0;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    left: 0;
    right: 0;
  }

  .custom-image-uploader::-webkit-file-upload-button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: transparent;
  }
}

.custom-dropzone.mini-uploader {
  height: 80px;
  width: 80px;
  p {
    display: none;
  }
  .add-icon {
    display: block;
    font-size: 45px;
  }
}

.page-header {
  min-height: 45px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  h5 {
    font-size: 19px;
    font-weight: 400;
    margin-bottom: 0px;
  }

  .search-box {
    margin-left: 10px;

    .search-input {
      &:focus {
        box-shadow: none;
      }
    }
  }

  .filter-container {
    margin-left: 15px;
    width: 150px;
  }

  .create-action {
    margin-left: auto;
    background-color: $primary-100;
    color: white;
  }

  .create-action:hover {
    background-color: $primary-100;
  }
}

.react-bootstrap-table {
  table {
    thead {
      tr {
        height: 50px;
        th {
          color: $grey-light-100;
          font-weight: 400;
          font-size: 14px;
          text-transform: uppercase;
          text-align: left;
        }

        th:first-child {
          width: 80px;
          text-align: center;
        }

        th:last-child {
          width: 120px;
          text-align: right;
        }
      }
    }

    tbody {
      border-top: 0px !important;

      tr {
        height: 50px;
        cursor: pointer;
        td:first-child {
          text-align: center;
        }

        td:last-child {
          .actions {
            display: flex;
            justify-content: flex-end;
          }
        }

        td {
          text-align: left;
          font-weight: 400;
          font-size: 14px;
          border-bottom: 0.3 solid $grey-light-200;
          word-break: break-all;
        }
      }

      tr:hover {
        background-color: rgba(230, 230, 230, 0.007);
      }
    }
  }
}
.react-bootstrap-table-pagination {
  padding: 0 20px;
  div:first-child {
    text-align: left;
    font-size: 14px;
    .custom-select {
      width: unset;
      margin-left: 10px;
      margin-right: 10px;
      font-size: 13px;
    }

    .react-bootstrap-table-pagination-total {
      margin-left: 10px;
    }
  }
  .react-bootstrap-table-pagination-list {
    ul {
      justify-content: flex-end;
    }
  }
}
.custom-icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border-radius: 5px;
  background-color: $grey-light-300;
  border-color: $grey-light-300;
  transition: background-color 0.2s;
  cursor: pointer;
  svg {
    height: 20px;
    width: 20px;
    color: $primary-200;
    transition: color 0.2s;
  }

  img {
    width: 100%;
  }
}
.custom-icon:hover {
  background-color: $primary-200;
  svg {
    color: white;
  }
}

.custom-icon.danger {
  background-color: $danger-100;
  svg {
    color: white;
  }
}

.custom-icon.danger:hover {
  background-color: $danger-200;
  svg {
    color: $danger-100;
  }
}

.custom-icon.primary {
  background-color: $primary-100;
  margin: 0;
  svg {
    color: white;
  }
}

.custom-icon.primary:hover {
  background-color: $primary-200;
  svg {
    color: $primary-100;
  }
}

.react-select__control {
  min-height: 42px !important;
  .react-select__indicators {
    .react-select__indicator-separator {
      display: none;
    }
  }

  .react-select__value-container {
    .react-select__single-value {
      font-size: 15px !important;
    }
  }
}

.react-select__control--is-focused {
  box-shadow: none !important;
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.custom-checkbox {
  .MuiFormControlLabel-label {
    color: $grey-light;
    font-size: 14px;
  }
}

.custom-editor {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: 3px;

  .custom__rich-editor-wrapper {
    border-radius: 3px !important;

    .rdw-editor-toolbar {
      margin-bottom: 0px;
      border: none !important;
      border-radius: 3px !important;

      //Block
      .rdw-block-wrapper {
        .rdw-dropdown-wrapper {
          border: none !important;
          a {
            color: #b5b5c3 !important;
            text-decoration: none !important;
            .rdw-dropdown-carettoopen {
              border-top: 6px solid #b5b5c3;
            }
          }
        }
      }

      .rdw-block-wrapper:hover {
        .rdw-dropdown-wrapper {
          box-shadow: none !important;
        }
      }

      //inline
      .rdw-inline-wrapper {
        .rdw-option-wrapper {
          border: none !important;
          img {
            opacity: 0.29;
          }
        }
      }

      .rdw-inline-wrapper:hover {
        .rdw-option-wrapper {
          box-shadow: none !important;
        }
      }

      //font-size
      .rdw-fontsize-wrapper {
        .rdw-dropdown-wrapper {
          border: none !important;
          .rdw-dropdown-selectedtext {
            color: #b5b5c3 !important;
            text-decoration: none;
            img {
              opacity: 0.29;
            }
            .rdw-dropdown-carettoopen {
              border-top: 6px solid #b5b5c3;
            }
          }
        }
      }

      .rdw-fontsize-wrapper:hover {
        .rdw-dropdown-wrapper {
          box-shadow: none !important;
        }
      }

      //link, list
      .rdw-list-wrapper,
      .rdw-text-align-wrapper,
      .rdw-link-wrapper {
        .rdw-option-wrapper {
          border: none !important;
          img {
            opacity: 0.5;
          }
        }
      }

      .rdw-list-wrapper:hover,
      .rdw-text-align-wrapper:hover,
      .rdw-link-wrapper:hover {
        .rdw-option-wrapper {
          box-shadow: none !important;
        }
      }
    }

    .hide-toolbar {
      display: none !important;
    }

    .custom__rich-editor.custom__rich-editor-detail {
      border-top: none !important;
    }

    .custom__rich-editor {
      border-top: 1px solid rgba(0, 0, 0, 0.23) !important;
      padding: 0 10px;
      min-height: 500px;
      max-height: fit-content;
      overflow-y: auto;
    }
  }
}

.form-item {
  margin-bottom: 8px;
}

.MuiTabs-root {
  .MuiTabs-fixed {
    .MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.Mui-selected {
      background-color: white;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border: none;
    }

    .MuiTabs-indicator {
      display: none !important;
    }
  }
}

.custom-form-textarea-style {
  padding: 10px;
  border: 1px solid #e2e7f1;
  border-radius: 4px;
}

.custom-form-textarea-style:focus {
  outline: none;
}

.btn-custom-primary {
  background-color: $button-color-primary !important;
  color: white !important;
}

.image-view-container {
  width: 330px;
  height: 200px;
  img {
    width: 100%;
    height: 100%;
  }
}
.document-container {
  position: relative;
  width: 155px !important;
  height: 60px !important;
  background-color: #fff !important;
  border: 1px solid #dcdcdc;
  margin: 0 15px 10px 0;
  padding: 8px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700 !important;
  line-height: 15px;
  display: flex;
  word-wrap: anywhere;
  color: #606060;
  cursor: pointer;
  box-shadow: 0px 3px 5px -5px rgba(57, 54, 54, 0.67);
  -webkit-box-shadow: 0px 3px 5px -5px rgba(57, 54, 54, 0.67);
  -moz-box-shadow: 0px 3px 5px -5px rgba(57, 54, 54, 0.67);
  align-items: center;
  justify-content: space-evenly;
  .document-body {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;

    .document-name {
      width: 130px;
      margin-bottom: 0px;
    }
  }
}

.document-container:hover {
  .document-footer {
    visibility: visible;
  }
}

.image-document {
  height: 135px;
  width: 140px;
  padding: 10px;
  border: 1px solid $border-color;
  border-radius: 5px;
  margin-right: 15px;
  cursor: pointer;
  position: relative;
  .image-document-body {
    .document-sneek {
      width: 120px;
      height: 80px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .document-name {
      display: flex;
      margin-top: 10px;
      svg {
        font-size: 20px;
        margin-right: 5px;
        color: #b34766;
      }
      p {
        font-size: 14px;
      }
    }
  }
}

.image-document:hover {
  .document-footer {
    visibility: visible;
  }
}

.document-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-color: #ffffff;
  background-color: #ffffff;
  right: -10px;
  top: -10px;
  height: 20px;
  width: 20px;
  box-shadow: 1px 2px 3px 0px rgb(24 28 50 / 25%) !important;
  border-radius: 50%;
  visibility: hidden;

  .document-remove {
    padding: 0;
  }
}
.document-remove {
  color: black;
  font-size: 13px;
}

.empty-data-message {
  text-align: center;
  padding: 20px;

  svg {
    height: 200px;
    width: 300px;
    color: #ff6666;
  }
}

.image-view-modal,
.view-modal {
  max-width: fit-content;
  margin: 0 !important;
  .modal-content {
    width: 100vw;
    min-height: 100vh;
    border-radius: 0px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));

    .modal-header {
      border-bottom: 0px;
      color: white;
      .modal-title {
        width: 100%;
        display: flex;
        align-items: center;
        .image-icon {
          margin-right: 10px;
        }
        .close-button {
          color: white;
          margin-left: auto;
        }
      }
    }
    .modal-body {
      display: flex;
      justify-content: center;
      .image-view-modal-container {
        max-width: 900px;
        img {
          width: 100%;
        }
      }
    }
  }
}

.custom-dateField {
  display: flex;
  align-items: center;
  height: 40px;
  img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  .date-display {
    margin-left: 8px;
  }
  input[type="date"] {
    border: none !important;
    padding: 0;
    background: transparent;
    margin-left: 8px;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    background: transparent;
    width: 100%;
    height: 30px;
    border-width: thin;
    margin-left: 0px;
    position: absolute;
    cursor: pointer;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
  }
}

.dynamic-url-container {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: fit-content;
  .main-url {
    background-color: $grey-light-300;
    padding: 10.5px 8px;
    color: #616161;
    font-size: 14px;
    border-radius: 5px 0 0 5px;
  }

  .custom-text-field {
    width: 300px;
    .MuiInputBase-root {
      input {
        border-radius: 0px !important;
        border-top: 0px !important;
        border-bottom: 0px !important;
        border-right: 0px !important;
        border-left: 1px solid rgba(0, 0, 0, 0.23) !important;
      }
    }
  }
}

.preview-option {
  font-size: 14px;
  cursor: pointer;
  color: $primary-200;
  svg {
    height: 15px;
    width: 15px;
  }
}

.preview-option:hover {
  color: $primary-100;
}

.divider-label {
  margin-top: 10px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 400;
  color: $grey-light-200;
  font-size: 14px;
}

.response-from-item {
  margin-bottom: 15px;

  .custom-icon.primary {
    margin-right: 10px;
  }

  .custom-icon.danger {
    margin-left: 0px;
  }
}
