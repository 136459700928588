$text-color: #3f4254;
$grey-100: #e1e1e1;
$grey-light: #7d8c9a;
$grey-light-100: #b2b3b5;
$grey-light-200: #cacaca;
$grey-light-300: #f3f6f9;
$backgroundcolor-100: #1e1e2d;
$backgroundcolor-main: #eef0f8;
$backgroundcolor-200: #3e3e53;
$backgroundcolor-300: #e3e3f3;
$primary-100: #007bff;
$primary-200: #66b0ff;
$button-color-primary: #007bff;
$bright-grey: #ebedf3;
$danger-100: #ff6666;
$danger-200: #ffcccc;
$border-color: #dee2e6;
$gray-300: #e2e7f1;
$client_gray: #757575;
