.spinner-border {
  width: 1.5rem;
  height: 1.5rem;
  border: 0.18rem solid currentColor;
  border-right-color: transparent;
}

.loading-overlay {
  position: relative;
  z-index: 5;

  .spinner-div {
    position: absolute;
    height: 100%;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(24, 28, 50, 0.04);
    .text-message {
      color: black;
      font-size: 15px;
    }

    .spinner-inner-container {
      display: flex;
      align-items: center;
      background-color: white;
      padding: 10px;
      border-radius: 5px;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    }

    &.backgroundTransparent {
      .spinner-inner-container {
        background-color: unset !important;
        box-shadow: unset !important;
      }
    }
  }

  .loader {
    width: 60px; /* control the size */
    border-radius: 10%;
    aspect-ratio: 1;
    display: grid;
    -webkit-mask: conic-gradient(from 22deg, #0003, #000);
    mask: conic-gradient(from 22deg, #0003, #000);
    animation: load 1s steps(8) infinite;
  }
  .loader,
  .loader:before {
    --_g: linear-gradient(#007bff 0 0) 50%; /* update the color here */
    background: var(--_g) / 34% 8% space no-repeat,
      var(--_g) / 8% 34% no-repeat space;
  }
  .loader:before {
    content: "";
    transform: rotate(45deg);
  }
  @keyframes load {
    from {
      transform: rotate(0turn);
    }
    to {
      transform: rotate(1turn);
    }
  }
}

.loading-overlay.higherIndex {
  z-index: 1301;
}
.no-loading-overlay {
  position: page;
}
