@import "../../assets/scss/_variables";

.image-upload-modal {
  max-width: 550px !important;
  .upload-modal-message-box {
    cursor: pointer;
    background-color: white;
    justify-content: center;
    align-items: center;
    border: 2px dashed $client_gray;

    #upload-img-button {
      display: none;
    }
  }
}
