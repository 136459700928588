@import "../../assets/scss/_variables";

.login {
  background-color: $backgroundcolor-main;
  height: 100vh;

  .login__content {
    width: 450px;
    height: 550px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    .login__content-body {
      width: 100%;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .logo-container {
        width: 100%;
        text-align: center;
        .cimex-logo {
          width: 80%;
        }
      }

      h3 {
        font-size: 22px;
        margin-top: 50px;
      }

      p {
        font-size: 14px;
        color: $grey-light;
      }
      .login-form {
        .forgot-password-link {
          margin-left: auto;
          text-decoration: none;
          font-size: 14px;
          color: $primary-100;
        }

        .login-button {
          width: 100%;
          text-align: center;
        }
      }

      .footer {
        margin-top: 30px;
        text-align: center;
      }
    }
  }
}
