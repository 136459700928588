.input-group {
  width: 300px;
  height: fit-content;
  margin-top: auto;
  margin-bottom: auto;
}

.input-group-append .btn {
  z-index: 1 !important;
}
