@import "../../assets/scss/_variables";

.vertical-layout {
  .header {
    position: fixed;
    top: 0;
    left: 250px;
    right: 0;
    height: 72px;
    z-index: 100;
    background-color: #fff;
    box-shadow: 0 0.05rem 0.01rem rgb(75 75 90 / 8%);

    .header__custom-topbar {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      padding: 0 20px 0 0;

      .userprofile-toggler {
        margin-left: auto;
        .dropdown {
          display: flex;
          align-items: center;
          p {
            margin-bottom: 0;
            margin-right: 10px;
          }
          .custom__toggler {
            border: none;
            padding: 0;
            height: 20px;
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $grey-100;
            svg {
              color: $text-color;
              height: 15px;
              width: 15px;
            }
          }

          button:focus {
            box-shadow: none;
          }

          .dropdown-menu.show {
            width: 200px;
            box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
            .dropdown-item {
              display: block;
              width: 100%;
              padding: 0.35rem 1.5rem;
              clear: both;
              font-weight: 400;
              text-align: inherit;
              white-space: nowrap;
              border: 0;
              svg {
                width: 17px;
                height: 17px;
                margin-right: 5px;
              }
              font-size: 14px;
            }
            .dropdown-item:active {
              background-color: $grey-light;
            }
          }
        }
      }
    }
  }
  .sidebar {
    width: 250px;
    background: #fff;
    bottom: 0;
    padding: 0 0 30px;
    position: fixed;
    top: 0;
    transition-duration: 0.2s;
    box-shadow: 1px 0.05rem 0.01rem rgb(75 75 90 / 8%);
    z-index: 99;
    background-color: $backgroundcolor-100;

    .sidebar__content {
      .sidebar__content-top {
        padding: 15px;
        height: 72px;
        .logo-link {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: white;
          text-transform: uppercase;
          img {
            width: 100%;
          }
          // h2 {
          //   font-size: 23px;
          //   letter-spacing: 0.5px;
          //   margin-bottom: 0;
          //   margin-left: 10px;
          // }
        }
      }
      .sidebar__content-divider {
        margin-top: 10px;
        margin-bottom: 10px;
        height: 20px;
        background-color: $backgroundcolor-200;
      }
      .sidebar__content-menu {
        padding: 20px 0;
        .menu {
          li:not(.sub-menu) {
            height: 55px;
            padding: 10px 0 10px 20px;
            text-align: left;
            display: flex;
            align-items: center;
            cursor: pointer;
          }

          li {
            a,
            .trigger {
              color: #fff;
              font-size: small;
              letter-spacing: 0.5px;
              color: #a2a3b7;
              font-weight: 400;
              display: flex;
              align-items: center;
              text-decoration: none;

              svg {
                margin-right: 8px;
                font-size: 30px;
              }
            }
          }

          li.sub-menu {
            display: flex;
            flex-direction: column;
            align-items: start;
            height: fit-content;

            .trigger {
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: start;
              text-transform: none;
              height: 55px;
              width: 100%;
              padding: 10px 0 10px 20px;
              cursor: pointer;
            }

            .sub-menu-list {
              width: 100%;

              a {
                color: #fff;
                font-size: small;
                letter-spacing: 0.5px;
                color: #a2a3b7;
                font-weight: 400;
                display: flex;
                align-items: center;
                text-decoration: none;
                height: 55px;
                padding: 10px 0 0px 30px;

                svg {
                  margin-right: 8px;
                  font-size: 15px;
                }

                &:hover,
                &.active {
                  color: white;
                }
              }
            }
          }

          li.active:not(.sub-menu) {
            a {
              color: white;
              svg {
                color: $primary-100;
              }
            }
          }
          li.active.sub-menu {
            .trigger {
              background-color: $backgroundcolor-200;

              color: white;
              svg {
                color: $primary-100;
              }
            }
          }
          li:hover {
            a {
              color: white;
            }
          }
        }
      }
    }
  }

  .vertical-layout__content {
    margin-left: 250px;
    overflow: hidden;
    padding: 15px 12.5px 5px;
    min-height: 80vh;
    margin-top: 72px;
  }
  .footer {
    bottom: 0;
    padding: 10px 15px 5px;
    position: absolute;
    right: 0;
    color: $grey-light;
    left: 250px;

    .footer__content {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: space-between;

      p {
        font-size: 14px;
      }
    }
  }
}
